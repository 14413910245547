import React from 'react'

import './PositionCard.scss'
import clsx from 'clsx'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import { TuixTextComponent, TuixLinkComponent } from 'components/TuixWebcomponents'
import { LinkTarget, TextSize, TextVariant } from 'tuix-webcomponents'

import { Locale } from '../../utils/enums'
import { getLanguageByLocale } from '../../utils/language'

export interface Props {
    title: string
    time: string
    apply: string
    slug: string
    locale: Locale
}

export interface PositionCardModel {
    title: string
    apply: string
    time: string
    node_locale: Locale
    slug: string
}

export default ({ title, time, apply, slug, locale }: Props): JSX.Element => {
    const lang = getLanguageByLocale(locale)
    const { isDarkMode } = useDarkModeContext()
    const { isMobile } = useDeviceWidth()

    return (
        <div className={clsx('positionCardContainer', isDarkMode && 'dark')}>
            <TuixTextComponent
                size={isMobile ? TextSize.M : TextSize.S}
                color={TextVariant.PRIMARY}
                dark={isDarkMode}
                className="positionCardTitle"
            >
                {title}
            </TuixTextComponent>

            <div className="timeContainer">
                <TuixTextComponent size={TextSize.Body2} color={TextVariant.MAIN} dark={isDarkMode}>
                    {time}
                </TuixTextComponent>
            </div>

            <TuixLinkComponent
                href={`/${lang}/career/offers/${slug}`}
                target={LinkTarget.SELF}
                className="applyLink"
                dark={isDarkMode}
            >
                <TuixTextComponent
                    size={TextSize.Button}
                    color={TextVariant.MAIN}
                    dark={isDarkMode}
                    className="positionCardApply"
                >
                    {apply}
                </TuixTextComponent>
            </TuixLinkComponent>
        </div>
    )
}
