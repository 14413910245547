import { useStaticQuery, graphql } from 'gatsby'

export const usePageContent = () => {
    const {
        mainImage: { nodes: nodesMainImage },
        section1: { nodes: nodesSection1 },
        section2: { nodes: nodesSection2 },
        allContentfulBenefitsCards: { edges: nodesBenefitsCardsEdges },
        allContentfulPositionCard: { edges: nodesPositionCardEdges },
        allContentfulSeo: { nodes: nodesMetaData },
    } = useStaticQuery(graphql`
        query QueryCarreer {
            mainImage: allContentfulMainImage(
                filter: { contentful_id: { eq: "315dH42DZC2OrNKZCxjbWA" } }
            ) {
                nodes {
                    subtitle
                    textVariantSubtitle
                    title
                    textVariantTitle
                    node_locale
                    textPosition
                    fontColor
                    buttonText
                    backgroundImage {
                        title
                        file {
                            url
                        }
                    }
                    backgroundImageDark {
                        title
                        file {
                            url
                        }
                    }
                }
            }
            section1: allContentfulSection(
                filter: { contentful_id: { eq: "6v0LooFbVctFdfwx9pyRD3" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    title
                }
            }
            section2: allContentfulSection(
                filter: { contentful_id: { eq: "4jVCOKOjTeNeFBHeNiwPWe" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    title
                }
            }
            allContentfulBenefitsCards(sort: { fields: order, order: ASC }) {
                edges {
                    node {
                        image {
                            file {
                                url
                            }
                        }
                        text
                        textSide
                        color
                        node_locale
                    }
                }
            }
            allContentfulPositionCard(sort: { fields: order, order: ASC }) {
                edges {
                    node {
                        title
                        apply
                        time
                        node_locale
                        slug
                    }
                }
            }

            allContentfulSeo(filter: { contentful_id: { eq: "7BdSKfND3gg5ZO0R5XXDrk" } }) {
                nodes {
                    title
                    url
                    description {
                        description
                    }
                    image {
                        file {
                            url
                        }
                    }
                    node_locale
                }
            }
        }
    `)

    return {
        nodesMainImage,
        nodesSection1,
        nodesSection2,
        nodesBenefitsCardsEdges,
        nodesPositionCardEdges,
        nodesMetaData,
    }
}
