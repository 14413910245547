import React from 'react'

import { TextSize, Palette, DarkPalette } from 'tuix-webcomponents'
import { Locale } from 'utils/enums'
import './BenefitCard.scss'
import clsx from 'clsx'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import { TuixTextComponent } from 'components/TuixWebcomponents'

export enum TextSide {
    TOP = 'top',
    BOTTOM = 'bottom',
}

export interface Props {
    text: string
    image: string
    color: string
    textSide: TextSide
}

interface File {
    url: string
}

interface Image {
    file: File
}

export interface BenefitCardModel {
    image: Image
    text: string
    textSide: TextSide
    color: string
    node_locale: Locale
}

export default ({ text, image, textSide, color }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const directionClass =
        textSide === 'bottom' ? 'benefitCard_direction' : 'benefitCard_directionReverse'
    const { isDesktop } = useDeviceWidth()

    return (
        <div className="benefitCard_container">
            <div
                className={clsx('benefitCard_circle', isDarkMode && 'dark')}
                style={{ background: color }}
            ></div>
            <div className="line_container">
                <hr className={clsx('dotted_line', isDarkMode && 'dark')} />
            </div>
            <div className={clsx('text_image_container', directionClass)}>
                <div className="benefitCard_background_wraper">
                    <div className={clsx('benefitCard_background', isDarkMode && 'dark')}>
                        <img src={image} className="benefitCard_img" alt="benefit card image" />
                    </div>
                </div>
                <div className="line_container_2">
                    <hr className={clsx('dotted_line', isDarkMode && 'dark')} />
                </div>
                <TuixTextComponent
                    size={isDesktop ? TextSize.S : TextSize.M}
                    dark={isDarkMode}
                    className="benefitCard_title"
                >
                    {text}
                </TuixTextComponent>
            </div>
        </div>
    )
}
